<template>
  <div class="downloadApp">
    <div class="imgList">
      <img src="@/assets/app1.png" alt="" />
      <img src="@/assets/app2.png" alt="" />
      <img src="@/assets/app3.png" alt="" />
      <img src="@/assets/app4.png" alt="" />
    </div>
    <div class="downloadBtn" @click="getApk">下载APP</div>
    <p>
      四川名优世汇药业有限公司，专注于大健康领域业务涵盖健康咨询，大健康产品代理。
      公司产品涉及保健食品、进口食品、母婴产品、日化用品、计生用品、医疗器械等。旗下经营品牌有汤臣倍健、Swisse
      、养生堂、惠氏、善存等国内外知名品牌。主营渠道为0TC药房、母婴门店、新零售、社群营销、企业团购等。
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      apiUrl: "http://118.24.138.2/api/common/getAndroidAppInfo",
    };
  },
  methods: {
    downLoadApp() {
      location.href =
        "https://app.cdo.oppomobile.com/home/detail?app_id=30923161";
    },
    getApk() {
      this.axios
        .get(this.apiUrl)
        .then((res) => {
          if (res.data.code == 200) {
            window.location = res.data.content.url;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.downloadApp {
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  .downloadBtn {
    text-align: center;
    width: 100px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    background-color: red;
    color: #fff;
    font-size: 14px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .imgList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    img {
      width: 300px;
      height: auto;
    }
  }
  p {
    text-align: left;
    text-indent: 2em;
    font-size: 18px;
  }
}
</style>